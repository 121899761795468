import React, { FC } from 'react';
import {
  IconChevron,
  IconDownload,
  IconUpload,
  IconWlan,
  Spacer,
  Text,
  Expandable,
  Footnote,
} from 'dss-ui-library';
import { Footnote as IFootnote } from '../../../interfaces/template';
import styles from './InternetDescription.module.scss';
import planStyles from '../PlanElements.module.scss';
import cx from 'classnames';
import { ProductInfoDetails, useProductContext } from '../../ProductContext';
import { Product } from '@ncs-frontend-monorepo/order';

interface InternetDescriptionProps {
  plan: Product;
  uploadFootnote?: IFootnote['footnote'];
  onToggle?: () => void;
  expandable?: boolean;
  disabled?: boolean;
}

export const InternetDescription: FC<InternetDescriptionProps> = ({
  plan,
  uploadFootnote,
  expandable = false,
  disabled = false,
}) => {
  const { toggledDetail, toggleInfo } = useProductContext();
  const open = toggledDetail.includes(ProductInfoDetails.netspeed);

  const downloadText = (
    <Text appearance="t5">
      Download bis zu <span data-e2e="download">{plan.internet.download}</span>{' '}
      Mbit/s
    </Text>
  );

  const uploadText = (
    <Text appearance="t5">
      Upload bis zu <span data-e2e="upload">{plan.internet.upload}</span> Mbit/s{' '}
      {uploadFootnote && <Footnote number={uploadFootnote.number} />}
    </Text>
  );

  return (
    <>
      {plan.internet.name && (
        <div className={planStyles.description}>
          <div className={planStyles.descriptionHeadline}>
            {expandable && (
              <>
                <IconWlan
                  color="blue"
                  width={25}
                  height={25}
                  className={disabled ? planStyles.isDisabled : undefined}
                />
                <Spacer r={1} />
              </>
            )}
            <Text appearance="t5_2" e2e="internet-product-name">
              {plan.internet.name}
            </Text>
          </div>
          {expandable && (
            <IconChevron
              small
              color="blue"
              width={24}
              height={24}
              className={cx(
                planStyles.toggleIcon,
                open && planStyles.open,
                disabled && planStyles.isDisabled,
              )}
              onClick={() => {
                toggleInfo(ProductInfoDetails.netspeed);
              }}
            />
          )}
        </div>
      )}
      {expandable ? (
        <Expandable e2e="internet-description" expanded={open}>
          <Spacer l={3}>
            <div className={styles.bandwidthItemWrapper}>
              <div className={styles.bandwidthItem}>
                <IconDownload
                  color="blue"
                  width={24}
                  height={24}
                  className={disabled ? planStyles.isDisabled : undefined}
                />
                <Spacer r={1} />
                {downloadText}
              </div>
            </div>
            <div className={styles.bandwidthItemWrapper}>
              <div className={styles.bandwidthItem}>
                <IconUpload
                  color="blue"
                  width={24}
                  height={24}
                  className={disabled ? planStyles.isDisabled : undefined}
                />
                <Spacer r={1} />
                {uploadText}
              </div>
            </div>
          </Spacer>
        </Expandable>
      ) : (
        <>
          <div className={styles.bandwidthItemWrapper}>
            <div className={styles.bandwidthItem}>
              <IconDownload color="blue" width={24} height={24} />
              <Spacer r={1} />
              {downloadText}
            </div>
          </div>
          <div className={styles.bandwidthItemWrapper}>
            <div className={styles.bandwidthItem}>
              <IconUpload color="blue" width={24} height={24} />
              <Spacer r={1} />
              {uploadText}
            </div>
          </div>
        </>
      )}
    </>
  );
};
